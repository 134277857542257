import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import bottomImage from "./images/bottom_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  render() {
    return (
      <div>
      <Header/>
      <div className="row">
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                  <div className="section-main-title">
                    <img src={heroImage}></img>
                  </div>

                  <div className="section-main-description">
                    <p><span style={{color: "#000", fontWeight: "bold"}}>Hi Colchester Cares Member!</span></p>
                    <p>Use this page to securely donate your $100 quarterly contribution which, combined with the other members’ $100, will be donated to the causes voted on at the last quarterly meeting.<br/><br/>
                    Your donation is always tax deductible. After you submit payment on this page, you will receive a receipt from the Givinga Foundation. Keep these in your records for tax purposes.
                    </p>
                    <p><span style={{color: "#000", fontWeight: "bold"}}>Where does your money go?</span></p>
                    <p>The money you donate on this page goes directly into our Donor Advised Fund, which is managed by our partner the Givinga Foundation, a 501(c)(3) public charity. Once we’ve collected all member donations for a given quarter, the Givinga Foundation mails the quarterly distribution checks to the causes that got the majority vote.<br></br></p>
                    <p>If you are experiencing any difficulty with this page, please email <a href="mailto:colchestercares@gmail.com">colchestercares@gmail.com.</a></p>
                  </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <DonationOptions stripeAction={this.showModal}/>
              <SocialComponent/>
            </div>
          </div>
      </div>
      <Footer/>
      </div>
    );
  }
}

export default withRouter(App);
