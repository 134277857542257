import React from "react";
import { Link, withRouter } from "react-router-dom";
import history from "../history";
import "./donation-options.scss";
import CurrencyInput from "react-currency-input";
import Modal from "react-modal";
import Terms from "../pages/page-terms";

const companyNames = [
  "Wealth Advisor Growth Network",
  "SDR Ventures",
  "Compass CFO Solutions",
  "Elk River Wealth Management",
  "Advisor Assist",
  "LibertyFi",
  "Goldenrod Companies",
  "Graham Media Partners",
  "Naviter Wealth",
  "Merchant",
  "AiK2 Insurance Services",
  "Primark Capital",
];

class DonationOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      charityId: 0,
      terms: false,
      showModal: false,
      currency: "usd",
      company: "",
    };

    this.cancel = "";

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleTermsChange = this.handleTermsChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleRouting = this.handleRouting.bind(this);
    this.handleACHRouting = this.handleACHRouting.bind(this);
    this.handleCharityChanged = this.handleCharityChanged.bind(this);
    this.handleCompanyChanged = this.handleCompanyChanged.bind(this);
    this.handleTransferRouting = this.handleTransferRouting.bind(this);
  }

  handleAmountChange(event) {
    var amount = event.target.value.replace(/[^0-9 ]/g, "");
    this.setState({ amount: amount });
  }

  handleAmountChange(event, maskedvalue, floatvalue) {
    this.setState({ amount: floatvalue });
  }

  handleTermsChange(event) {
    this.setState({ terms: event.target.checked });
  }

  handleCurrencyChange(event) {
    this.setState({ currency: event.target.value });
  }

  handleCompanyChanged(event) {
    this.setState({ company: event.target.value });
  }

  handleCharityChanged(event) {
    this.setState({ charityId: event.target.value });
  }

  async handleRouting() {
    if (this.state.amount >= 1 && this.state.terms) {
      history.push({
        pathname: "/donate",
        state: {
          amount: this.state.amount,
          currency: "usd",
        },
      });
    } else {
      alert(
        `Please make sure you have provided a donation amount of at least $1 and agreed to the terms and conditions.`
      );
    }
  }

  handleACHRouting() {
    if (this.state.amount >= 1 && this.state.terms) {
      if (this.state.currency !== "usd") {
        alert(`Donations via Bank Transfer must be in USD.`);
      } else {
        history.push({
          pathname: "/donate-ach",
          state: {
            amount: this.state.amount,
            currency: "usd",
          },
        });
      }
    } else {
      alert(
        `Please make sure you have provided a donation amount of at least $1 and agreed to the terms and conditions.`
      );
    }
  }

  handleTransferRouting() {
    history.push({
      pathname: "/donate-transfer",
    });
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div className="donation-container">
           <div className="donation-container-help">
             <p>MAKE YOUR QUARTERLY DONATION</p>
             <div className="subhead">Set Donation Amount</div>
             <CurrencyInput className="currencyInput" prefix="$" value={this.state.amount} onChangeEvent={this.handleAmountChange}/>

             <div className="terms">
               <h5><input type="checkbox" onChange={this.handleTermsChange} value={this.state.terms}/>I accept the <Link onClick={this.handleOpenModal}>Terms & Conditions</Link></h5>
               <Modal isOpen={this.state.showModal}
               onRequestClose={this.handleCloseModal}
               className="Modal"
               overlayClassName="Overlay"
               contentLabel="Terms and Conditions">
               <Terms closeModal={this.handleCloseModal}/>
               </Modal>
             </div>

             <div className="button-container">
               <button onClick={this.handleRouting}>Donate Now</button>
             </div>
           </div>
       </div>
   );
  }
}

export default withRouter(DonationOptions);
