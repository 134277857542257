import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import axios from 'axios';
import qs from 'querystring';
import Header from '../components/header';
import Footer from '../components/footer.js';
import CurrencyInput from 'react-currency-input';
import { Link, withRouter } from "react-router-dom";
import history from '../history';
import './page-donate.scss';
const data = require("../data/data.json");

const config = {
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
};

class ACHDonationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      email: "",
      name: "",
      amount: "",
      org: data.org
    };

    this.emailUpdate = this.emailUpdate.bind(this);
    this.nameUpdate = this.nameUpdate.bind(this);
    this.amountUpdate = this.amountUpdate.bind(this);
  }

  emailUpdate(event) {
    this.setState({email: event.target.value})
  }

  nameUpdate(event) {
    this.setState({name: event.target.value})
  }

  amountUpdate(event, maskedvalue, floatvalue) {
    this.setState({amount: floatvalue});
  }

  handleSubmit = async (event) => {
    this.setState({ processing: true });

    if (this.state.name === "" || this.state.email === "") {
      this.setState({ processing: false });
      alert("Please provide your name, email address and an amount greater than $0.")
      event.preventDefault();
      return;
    }
    event.preventDefault();

    const body = {
      sender: this.state.email,
      name: this.state.name,
      amount: `$${this.state.amount}`,
      org: this.state.org
    }

    const response = await axios.post(`https://covid-relief-api.herokuapp.com/send-ach-form`, body, config)
    .catch(error => {
        this.setState({ processing: false });
        alert("Failed to submit ACH form, please try again later.")
        return;
    });


    if (response !== undefined) {
      this.setState({processing: false});
      history.push({
        pathname: '/'
      });
      return;
    }
  };

  render() {
    return (
      <div>
      <Header/>
      <div className="ach-disclaimer">
        If you are interested in mailing a check to make a donation, please submit the form below. <b>The check should be made out to the Givinga Foundation</b>, our Partner and Donor Advised Fund. <b>Please write “Colchester Cares” in the memo field.</b> Please mail the check to the following address:<br/>
        Givinga Foundation<br/>
        396 Washington St.<br/>
        Suite 307<br/>
        Wellesley, MA 02481<br/>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="donate-page-container">
        <div className="donate-page-container-title">
          <form onSubmit={this.handleSubmit}>
          <div className="checkout-form">
            <input placeholder="Name*" value={this.state.name} onChange={this.nameUpdate}/>
            <input placeholder="Email*" value={this.state.email} onChange={this.emailUpdate}/>
            <div className="subtitle">Donation Amount</div>
            <CurrencyInput className="currencyInput" prefix="$" value={this.state.amount} onChangeEvent={this.amountUpdate}/>
          </div>
          <button type="submit" disabled={this.state.processing}>{this.state.processing ? "Processing..." : "Submit"}</button>
          </form>
        </div>
      </div>
      <Footer/>
      </div>
    );
  }
}

export default withRouter(ACHDonationForm);